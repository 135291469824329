import { InferType, boolean, object, string } from 'yup';

export const CareDocumentCheckableItemSchema = object().shape({
  checked: boolean(),
  value: string().nullable().optional(),
});

export type CareDocumentCheckableItem = InferType<
  typeof CareDocumentCheckableItemSchema
>;
