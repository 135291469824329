import { object, string, InferType, date } from 'yup';
import { IamCancerSchema } from '../../../IamCancerSchema';
import { PcpPatientServiceListSchema } from '../pcp';
import { baseAiGeneratedResponseSchema } from './baseAiGeneratedResponseSchema';

export const PcpAiGeneratedSchema = object().shape({
  patient: object()
    .shape({
      iam: IamCancerSchema.nullable().notRequired(),
    })
    .nullable()
    .notRequired(),
  dateOfConsultation: date().nullable().notRequired(),
  notes: string().nullable().notRequired(),
  patientServices: PcpPatientServiceListSchema,
});

export type PcpAiGenerated = InferType<typeof PcpAiGeneratedSchema>;

export const PcpAiGeneratedResponseSchema =
  baseAiGeneratedResponseSchema(PcpAiGeneratedSchema);

export type PcpAiGeneratedResponse = InferType<
  typeof PcpAiGeneratedResponseSchema
>;
