import {
  AiCareDocumentGenerate,
  EndOfCareSummaryAiGenerated,
  EndOfCareSummaryAiGeneratedResponse,
  EndOfCareSummaryAiGeneratedResponseSchema,
  EndOfCareSummaryData,
  InitialAssessmentSummaryAiGenerated,
  InitialAssessmentSummaryAiGeneratedResponse,
  InitialAssessmentSummaryAiGeneratedResponseSchema,
  InitialAssessmentSummaryData,
  InterimCareSummaryAiGenerated,
  InterimCareSummaryAiGeneratedResponse,
  InterimCareSummaryAiGeneratedResponseSchema,
  InterimCareSummaryData,
  PcpAiGenerated,
  PcpAiGeneratedResponse,
  PcpAiGeneratedResponseSchema,
  PcpData,
  WorkFocusedPlanData,
} from '@packages/core-shared';
import { ApiRepository } from '../../helpers/ApiRepository';
import { getFetchResponseErrorMessage } from '../../helpers';

export class CareDocumentsApiRepository extends ApiRepository {
  async autosavePcpForm(form: Partial<PcpData>) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error autosaving PDF form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${form.caseId}/care-documents/pcps/form`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error autosaving PDF form');
    }
  }

  async generateAiPcpForm(caseId: string, body: AiCareDocumentGenerate) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error generating AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/pcps/form/ai/generate`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      throw Error(await getFetchResponseErrorMessage(response));
    }

    const data = await response.json();

    // convert strings to Date type
    try {
      return PcpAiGeneratedResponseSchema.cast(data);
    } catch {
      return data as PcpAiGeneratedResponse;
    }
  }

  async saveAiPcpForm(caseId: string, form: PcpAiGenerated) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error saving AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/pcps/form/ai`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error saving AI form');
    }
  }

  async createPcp(pcp: PcpData) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error creating PDF, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${pcp.caseId}/care-documents/pcps`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(pcp),
      },
    );

    if (!response.ok) {
      throw new Error('Error creating PDF');
    }

    const data = await response.json();

    return data as { id: string; storageRef: string };
  }

  async autosaveWorkFocusedPlanForm(form: Partial<WorkFocusedPlanData>) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error autosaving PDF form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${form.caseId}/care-documents/work-focused-plans/form`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error autosaving PDF form');
    }
  }

  async createWorkFocusedPlan(workFocusedPlan: WorkFocusedPlanData) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error creating PDF, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${workFocusedPlan.caseId}/care-documents/work-focused-plans`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(workFocusedPlan),
      },
    );

    if (!response.ok) {
      throw new Error('Error creating PDF');
    }

    const data = await response.json();
    return data as { id: string; storageRef: string };
  }

  async autosaveInitialAssessmentSummaryForm(
    form: Partial<InitialAssessmentSummaryData>,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error autosaving PDF form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${form.caseId}/care-documents/initial-assessment-summaries/form`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error autosaving PDF form');
    }
  }

  async generateAiInitialAssessmentSummaryForm(
    caseId: string,
    body: AiCareDocumentGenerate,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error generating AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/initial-assessment-summaries/form/ai/generate`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      throw new Error(await getFetchResponseErrorMessage(response));
    }

    const data = await response.json();

    // convert strings to Date type
    try {
      return InitialAssessmentSummaryAiGeneratedResponseSchema.cast(data);
    } catch {
      return data as InitialAssessmentSummaryAiGeneratedResponse;
    }
  }

  async saveAiInitialAssessmentSummaryForm(
    caseId: string,
    form: InitialAssessmentSummaryAiGenerated,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error saving AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/initial-assessment-summaries/form/ai`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error saving AI form');
    }
  }

  async createInitialAssessmentSummary(
    initialAssessmentSummary: InitialAssessmentSummaryData,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error creating PDF, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${initialAssessmentSummary.caseId}/care-documents/initial-assessment-summaries`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(initialAssessmentSummary),
      },
    );

    if (!response.ok) {
      throw new Error('Error creating PDF');
    }

    const data = await response.json();
    return data as { id: string; storageRef: string };
  }

  async autosaveInterimCareSummaryForm(form: Partial<InterimCareSummaryData>) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error autosaving PDF form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${form.caseId}/care-documents/interim-care-summaries/form`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error autosaving PDF form');
    }
  }

  async generateAiInterimCareSummaryForm(
    caseId: string,
    body: AiCareDocumentGenerate,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error generating AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/interim-care-summaries/form/ai/generate`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      throw new Error(await getFetchResponseErrorMessage(response));
    }

    const data = await response.json();

    // convert strings to Date type
    try {
      return InterimCareSummaryAiGeneratedResponseSchema.cast(data);
    } catch {
      return data as InterimCareSummaryAiGeneratedResponse;
    }
  }

  async saveAiInterimCareSummaryForm(
    caseId: string,
    form: InterimCareSummaryAiGenerated,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error saving AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/interim-care-summaries/form/ai`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error saving AI form');
    }
  }

  async createInterimCareSummary(interimCareSummary: InterimCareSummaryData) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error creating PDF, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${interimCareSummary.caseId}/care-documents/interim-care-summaries`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(interimCareSummary),
      },
    );

    if (!response.ok) {
      throw new Error('Error creating PDF');
    }

    const data = await response.json();
    return data as { id: string; storageRef: string };
  }

  async autosaveEndOfCareSummaryForm(form: Partial<EndOfCareSummaryData>) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error autosaving PDF form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${form.caseId}/care-documents/end-of-care-summaries/form`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error autosaving PDF form');
    }
  }

  async createEndOfCareSummary(endOfCareSummary: EndOfCareSummaryData) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error creating PDF, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${endOfCareSummary.caseId}/care-documents/end-of-care-summaries`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/pdf',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(endOfCareSummary),
      },
    );

    if (!response.ok) {
      throw new Error('Error creating PDF');
    }

    const data = await response.json();

    return data as { id: string; storageRef: string };
  }

  async generateAiEndOfCareSummaryForm(
    caseId: string,
    body: AiCareDocumentGenerate,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error generating AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/end-of-care-summaries/form/ai/generate`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      throw new Error(await getFetchResponseErrorMessage(response));
    }

    const data = await response.json();

    // convert strings to Date type
    try {
      return EndOfCareSummaryAiGeneratedResponseSchema.cast(data);
    } catch {
      return data as EndOfCareSummaryAiGeneratedResponse;
    }
  }

  async saveAiEndOfCareSummaryForm(
    caseId: string,
    form: EndOfCareSummaryAiGenerated,
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Error saving AI form, unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/end-of-care-summaries/form/ai`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      },
    );

    if (!response.ok) {
      throw new Error('Error saving AI form');
    }
  }

  async uploadDocumentToHealee({
    caseId,
    documentId,
  }: {
    caseId: string;
    documentId: string;
  }) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/care-documents/${documentId}/healee`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      },
    );

    if (!response.ok) {
      throw new Error('Error uploading document to Healee');
    }
  }
}
