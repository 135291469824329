import { object, array, string, ObjectSchema, AnyObject, Maybe } from 'yup';
import { AppointmentSchema } from '../../../appointments';

export const baseAiGeneratedResponseSchema = <TIn extends Maybe<AnyObject>>(
  schema: ObjectSchema<TIn>,
) =>
  object().shape({
    form: schema,
    appointmentsWithTranscriptions: array().of(AppointmentSchema).required(),
    documentsUsed: array().of(string()).required(),
  });
