import {
  AlertError,
  Button,
  FormFieldset,
  Modal,
  Spinner,
  TextArea,
  Tooltip,
} from '@percihealth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSubscribePatientAppointmentsWithTranscriptions } from '@packages/web-shared/api';
import QuestionIcon from '@packages/web-shared/components/icons/QuestionIcon';
import styles from './GenerateByAiButton.module.css';
import { SelectAppointmentsTranscriptionsList } from '../SelectAppointmentsTranscriptionsList';
import {
  AiCareDocumentGenerate,
  Referral,
  ReferralLandg,
} from '@packages/core-shared';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { SelectReferralDocumentsList } from '../SelectReferralDocumentsList';

enum ModalProgress {
  Step1 = 'step1',
  Step2 = 'step2',
}

export const GenerateByAiButton = ({
  caseId,
  referralId,
  generateAiForm,
  onFillForm,
  children,
}: {
  caseId: string;
  referralId: string | null;
  generateAiForm: (data: AiCareDocumentGenerate) => Promise<void>;
  onFillForm: () => void;
  children: React.ReactNode;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [step, setStep] = useState<ModalProgress>(ModalProgress.Step1);
  const [selectedAppointments, setSelectedAppointments] = useState<string[]>(
    [],
  );
  const [selectedDocumentsPaths, setSelectedDocumentsPaths] = useState<
    string[]
  >([]);
  const [prompt, setPrompt] = useState<string>('');

  const referralLoader = useGetFirestoreDocument<Referral>(
    'referrals',
    referralId,
  );

  const documents = useMemo(() => {
    const docs = (referralLoader.record as ReferralLandg | undefined)
      ?.documents;
    return [docs?.latestClinicalReport, ...(docs?.referrals ?? [])].filter(
      Boolean,
    );
  }, [referralLoader.record]);

  const resetClosedState = () => {
    setOpenModal(false);
    setErrorMessage(null);
    setStep(ModalProgress.Step1);
    setPrompt('');
  };

  useEffect(() => {
    resetClosedState();
  }, [caseId]);

  const closeModal = useCallback(() => {
    resetClosedState();
  }, []);

  const appointmentsSubscriber =
    useSubscribePatientAppointmentsWithTranscriptions({
      patientEmail: caseId,
    });

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onOpenModalClick = useCallback(() => {
    setSubmitting(false);
    setErrorMessage(null);
    setOpenModal(true);
  }, []);

  useEffect(() => {
    // select all by default
    setSelectedAppointments(
      appointmentsSubscriber.records.map((record) => record.id),
    );
  }, [appointmentsSubscriber.records, caseId, openModal]);

  useEffect(() => {
    // select all by default
    setSelectedDocumentsPaths(documents.map((record) => record!.path));
  }, [documents, caseId, openModal]);

  if (appointmentsSubscriber.loading || referralLoader.loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div className={styles['btn-container']}>
        <Button
          disabled={appointmentsSubscriber.records.length === 0}
          onClick={onOpenModalClick}
        >
          Fill by AI
        </Button>
        {!appointmentsSubscriber.errorMessage &&
          appointmentsSubscriber.records.length === 0 && (
            <Tooltip
              trigger={
                <div>
                  <QuestionIcon />
                </div>
              }
            >
              <div>Appointment transcription is not created yet.</div>
            </Tooltip>
          )}
      </div>

      <div>
        {appointmentsSubscriber.errorMessage && (
          <AlertError>{appointmentsSubscriber.errorMessage}</AlertError>
        )}
        {referralLoader.errorMessage && (
          <AlertError>{referralLoader.errorMessage}</AlertError>
        )}
      </div>

      <Modal
        open={openModal}
        onClose={closeModal}
        center
        classNames={{ modal: styles.modal }}
      >
        <div>
          <>
            {step === ModalProgress.Step1 && (
              <div>
                <h2 className={styles.header}>Fill by AI</h2>
                {errorMessage && (
                  <div>
                    <h3>Error</h3>
                    <AlertError>{errorMessage}</AlertError>
                    <br />
                    <Button onClick={closeModal}>Close</Button>
                  </div>
                )}

                {!errorMessage && (
                  <>
                    <FormFieldset
                      title="Appointments"
                      className={styles.fieldset}
                    >
                      <div className="full-width">
                        <SelectAppointmentsTranscriptionsList
                          appointments={appointmentsSubscriber.records}
                          selectedAppointments={selectedAppointments}
                          onChange={setSelectedAppointments}
                        />
                      </div>
                    </FormFieldset>
                    <FormFieldset
                      title="Referral documents"
                      className={styles.fieldset}
                    >
                      <div className="full-width">
                        <SelectReferralDocumentsList
                          documents={documents as any}
                          selectedDocumentsPaths={selectedDocumentsPaths}
                          onChange={setSelectedDocumentsPaths}
                        />
                      </div>
                    </FormFieldset>
                    <FormFieldset
                      title="Instructions"
                      className={styles.fieldset}
                    >
                      <div className="full-width">
                        <TextArea
                          label="Instructions to AI (optional)"
                          disabled={submitting}
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                          placeholder='e.g. Make "Notes" no longer than 32 characters, do not mention type of cancer in "Notes"'
                        />

                        <p className={styles.note}>
                          If there is a need to modify specific field please
                          mention it exactly, for example, "Treatment - Other
                          medical relevant history", or "Notes", "Expert
                          Summary" for the whole summary, or "Expert Summary -
                          Assessment of capability to return back to work" for
                          specific sub field.
                          <br />
                          Separate multiple instructions with a blank new line.
                        </p>
                      </div>
                    </FormFieldset>
                    {selectedAppointments.length === 0 && (
                      <AlertError>
                        At least one appointment should be selected
                      </AlertError>
                    )}
                    <div className={styles['action-buttons']}>
                      <div />
                      <Button
                        disabled={
                          selectedAppointments.length === 0 ||
                          errorMessage !== null
                        }
                        level="secondary"
                        submitting={submitting}
                        onClick={async () => {
                          setSubmitting(true);
                          setErrorMessage(null);
                          try {
                            await generateAiForm({
                              appointmentIds: selectedAppointments,
                              prompt,
                              documentsPaths: selectedDocumentsPaths,
                            });
                            setStep(ModalProgress.Step2);
                          } catch (err) {
                            console.log(err);
                            setErrorMessage((err as Error).message);
                          } finally {
                            setSubmitting(false);
                          }
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
            {step === ModalProgress.Step2 && (
              <>
                <div>{children}</div>
                <div>
                  <p>Import AI data to the current form?</p>
                  {errorMessage && (
                    <div>
                      <h3>Error</h3>
                      <AlertError>{errorMessage}</AlertError>
                      <br />
                      <Button onClick={closeModal}>Close</Button>
                    </div>
                  )}
                  <div className={styles['action-buttons']}>
                    <div className={styles['btn-container-2']}>
                      <Button
                        level="tertiary"
                        onClick={() => {
                          setStep(ModalProgress.Step1);
                        }}
                      >
                        Back
                      </Button>
                      <Button
                        level="primary"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>

                    <Button
                      level="secondary"
                      disabled={errorMessage !== null}
                      onClick={() => {
                        onFillForm();
                        closeModal();
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </Modal>
    </div>
  );
};
