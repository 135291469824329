import { object, string, InferType, date } from 'yup';
import { deepPartialOrNullable } from '../../../../helpers';
import { baseAiGeneratedResponseSchema } from './baseAiGeneratedResponseSchema';
import { AiCareDocumentWorkDetailsSchema } from './AiCareDocumentWorkDetailsSchema';

export const InterimCareSummaryAiGeneratedSchema = object().shape({
  patient: object()
    .shape({
      cancerType: string().nullable().notRequired(),
      dob: date().nullable().notRequired(),
      work: deepPartialOrNullable(AiCareDocumentWorkDetailsSchema)
        .nullable()
        .notRequired(),
    })
    .nullable()
    .notRequired(),

  expertSummary: object()
    .shape({
      motivation: string().nullable().notRequired(),
    })
    .nullable()
    .notRequired(),
});

export type InterimCareSummaryAiGenerated = InferType<
  typeof InterimCareSummaryAiGeneratedSchema
>;

export const InterimCareSummaryAiGeneratedResponseSchema =
  baseAiGeneratedResponseSchema(InterimCareSummaryAiGeneratedSchema);

export type InterimCareSummaryAiGeneratedResponse = InferType<
  typeof InterimCareSummaryAiGeneratedResponseSchema
>;
