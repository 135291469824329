import { InferType, bool, date, object, string } from 'yup';

export const AiCareDocumentWorkDetailsSchema = object().shape({
  jobTitle: string().nullable().notRequired(),
  dateSignedOff: date().nullable().notRequired(),
  workPattern: string().nullable().notRequired(),
  hideHopingToReturnToWorkIn: bool().nullable().notRequired(),
  hopingToReturnToWorkIn: string().nullable().notRequired(),
});

export type AiCareDocumentWorkDetails = InferType<
  typeof AiCareDocumentWorkDetailsSchema
>;
