import { Profile } from '@packages/core-shared';
import { ApiRepository } from '../../helpers/ApiRepository';

export class ProfileApiRepository extends ApiRepository {
  async get() {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/profile`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'GET',
      },
    );

    if (!response.ok) {
      throw new Error('failed to load profile');
    }

    return (await response.json()) as Profile;
  }

  async update(user: Profile) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/profile`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(user),
      },
    );

    if (!response.ok) {
      throw new Error('failed to update profile');
    }
  }
}
