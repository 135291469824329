import { InferType, date, number, object, string } from 'yup';
import { HealeeMedicalHistoryPatientAddressSchema } from './HealeeMedicalHistoryPatientAddressSchema';

export const HealeeMedicalHistoryPatientSchema = object().shape({
  dateOfBirth: date(),
  address: HealeeMedicalHistoryPatientAddressSchema.optional()
    .nullable()
    .default(undefined),
  gender: number().nullable().default(undefined),
  // in IN
  height: number().nullable().default(undefined),
  // in LB
  weight: number().nullable().default(undefined),
  ethnicity: string().nullable().default(undefined),

  cancerType: string(),
  cancerStage: string().nullable().default(undefined),
  cancerYearOfDiagnosis: number().nullable().default(undefined),

  gpPracticeName: string(),
  gpName: string().nullable().default(undefined),

  // When it was sent to Healee
  at: date(),
  by: string(),
});

export type HealeePatientMedicalHistory = InferType<
  typeof HealeeMedicalHistoryPatientSchema
>;
