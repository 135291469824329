import { object, string, InferType, date, array } from 'yup';
import { deepPartialOrNullable } from '../../../../helpers';
import { baseAiGeneratedResponseSchema } from './baseAiGeneratedResponseSchema';
import { AiCareDocumentTreatmentDetailsSchema } from './AiCareDocumentTreatmentDetailsSchema';
import { AiCareDocumentWorkDetailsSchema } from './AiCareDocumentWorkDetailsSchema';
import { CareDocumentOvercomeChallengeSchema } from '../CareDocumentOvercomeChallengeSchema';

export const InitialAssessmentSummaryAiGeneratedSchema = object().shape({
  patient: object()
    .shape({
      cancerType: string().nullable().notRequired(),
      dob: date().nullable().notRequired(),
      dateOfDiagnosis: date().nullable().notRequired(),
      work: deepPartialOrNullable(AiCareDocumentWorkDetailsSchema)
        .nullable()
        .notRequired(),
    })
    .nullable()
    .notRequired(),
  perciStartDate: date().nullable().notRequired(),
  dateOfConsultation: date().nullable().notRequired(),

  treatmentDetails: deepPartialOrNullable(AiCareDocumentTreatmentDetailsSchema)
    .nullable()
    .notRequired(),

  planToOvercomeChallenges: array()
    .of(deepPartialOrNullable(CareDocumentOvercomeChallengeSchema))
    .nullable()
    .notRequired(),

  expertSummary: string().nullable().notRequired(),
});

export type InitialAssessmentSummaryAiGenerated = InferType<
  typeof InitialAssessmentSummaryAiGeneratedSchema
>;

export const InitialAssessmentSummaryAiGeneratedResponseSchema =
  baseAiGeneratedResponseSchema(InitialAssessmentSummaryAiGeneratedSchema);

export type InitialAssessmentSummaryAiGeneratedResponse = InferType<
  typeof InitialAssessmentSummaryAiGeneratedResponseSchema
>;
