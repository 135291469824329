import { FormikState } from 'formik';

// formik.isValid can show false while formik.errors is empty (not yet validated) during the first load
// we allow to submit the form in that case, then Formik does auto validation and will stop submission if there are errors
export const disableSubmitButton = (formik: FormikState<any>) => {
  return hasStateErrors(formik);
};

export const hasStateErrors = (formik: FormikState<any>) => {
  return formik.errors && Object.keys(formik.errors).length > 0;
};
