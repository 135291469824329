export default function DropdownIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <rect x="0" fill="none" width="24" height="24" />
      <g>
        <path d="M7 10l5 5 5-5" />
      </g>
    </svg>
  );
}
