import { InferType, object, string } from 'yup';

export const AiCareDocumentTreatmentDetailsSchema = object().shape({
  treatment: string().nullable().notRequired(),
  plan: string().nullable().notRequired(),
  expectedEndDate: string().nullable().notRequired(),
  other: string().nullable().notRequired(),
});

export type AiCareDocumentTreatmentDetails = InferType<
  typeof AiCareDocumentTreatmentDetailsSchema
>;
