import { CareDocumentsApiRepository } from '@packages/web-shared/api';
import { useCallback, useMemo, useState } from 'react';
import {
  EndOfCareSummaryAiGeneratedResponse,
  EndOfCareSummaryData,
  EndOfCareSummaryAiGenerated,
  dateToLondonNumericDateString,
  AiCareDocumentGenerate,
} from '@packages/core-shared';
import richTextHtml from '../components/richTextHtml';
import { useFirebase } from '@packages/web-shared';
import { ReferralDocumentsUsedList } from '../components/ReferralDocumentsUsedList';
import { GenerateByAiButton } from '../components/GenerateByAiButton';
import styles from '../components/GenerateByAiButton/GenerateByAiButton.module.css';
import { FormFieldset } from '@percihealth/react';

interface Props {
  caseId: string;
  referralId: string | null;
  currentForm: EndOfCareSummaryData;
  onChange: (data: EndOfCareSummaryAiGenerated) => void;
}

export const EndOfCareSummaryGenerateByAiButton = ({
  caseId,
  referralId,
  currentForm,
  onChange,
}: Props) => {
  const { auth } = useFirebase();

  const [aiFormResponse, setAiFormResponse] =
    useState<EndOfCareSummaryAiGeneratedResponse | null>(null);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const generateAiForm = useCallback(
    async (data: AiCareDocumentGenerate) => {
      const result = await docsApiRepository.generateAiEndOfCareSummaryForm(
        caseId,
        data,
      );
      setAiFormResponse(result);
    },
    [docsApiRepository],
  );

  const formatCheckableItem = useCallback(
    (item?: { checked?: boolean | null; value?: string | null }) => {
      if (!item) return '';
      return `${item.checked ? '✅' : '⬜'} ${item.value && item.value != '' ? item.value : '(empty)'}`;
    },
    [],
  );

  return (
    <GenerateByAiButton
      caseId={caseId}
      referralId={referralId}
      generateAiForm={generateAiForm}
      onFillForm={() => {
        onChange(aiFormResponse!.form!);
      }}
    >
      <h2 className={styles.header}>AI generated form</h2>

      <FormFieldset title="Documents" className={styles.fieldset}>
        <ReferralDocumentsUsedList
          appointments={aiFormResponse?.appointmentsWithTranscriptions ?? []}
          documents={aiFormResponse?.documentsUsed ?? []}
        />
      </FormFieldset>

      <FormFieldset title="Form changes" className={styles.fieldset}>
        <div className="full-width">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Section</th>
                <th>Field</th>
                <th>Current form</th>
                <th>Incoming AI data</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Patient</td>
                <td>Cancer type:</td>
                <td>{currentForm.patient.cancerType}</td>
                <td
                  className={
                    aiFormResponse?.form?.patient?.cancerType !==
                    currentForm.patient.cancerType
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.cancerType}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Date of diagnosis:</td>
                <td>
                  {dateToLondonNumericDateString(
                    currentForm.patient.dateOfDiagnosis,
                  )}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.patient?.dateOfDiagnosis,
                    ) !==
                    dateToLondonNumericDateString(
                      currentForm.patient.dateOfDiagnosis,
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form.patient?.dateOfDiagnosis,
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>DOB:</td>
                <td>
                  {dateToLondonNumericDateString(currentForm.patient.dob)}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.patient?.dob,
                    ) !== dateToLondonNumericDateString(currentForm.patient.dob)
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form.patient?.dob,
                  )}
                </td>
              </tr>

              <tr>
                <td>Treatment</td>
                <td>Treatment to date:</td>
                <td>{currentForm.treatmentDetails.treatment}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.treatment !==
                    currentForm.treatmentDetails.treatment
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.treatment}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Ongoing treatment plan:</td>
                <td>{currentForm.treatmentDetails.plan}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.plan !==
                    currentForm.treatmentDetails.plan
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.plan}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Expected treatment end date:</td>
                <td>{currentForm.treatmentDetails.expectedEndDate}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.expectedEndDate !==
                    currentForm.treatmentDetails.expectedEndDate
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.expectedEndDate}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Other medical relevant history:</td>
                <td>{currentForm.treatmentDetails.other}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.other !==
                    currentForm.treatmentDetails.other
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.other}
                </td>
              </tr>

              <tr>
                <td>Return to work status</td>
                <td>Returned to work:</td>
                <td>
                  {formatCheckableItem(
                    currentForm.returnToWorkStatus.returnToWork,
                  )}
                </td>
                <td
                  className={
                    aiFormResponse?.form?.returnToWorkStatus?.returnToWork !==
                    currentForm.returnToWorkStatus.returnToWork
                      ? styles.changed
                      : undefined
                  }
                >
                  {formatCheckableItem(
                    aiFormResponse?.form?.returnToWorkStatus?.returnToWork,
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Return to work date agreed:</td>
                <td>
                  {formatCheckableItem(
                    currentForm.returnToWorkStatus.returnToWorkDateAgreed,
                  )}
                </td>
                <td
                  className={
                    aiFormResponse?.form?.returnToWorkStatus
                      ?.returnToWorkDateAgreed !==
                    currentForm.returnToWorkStatus.returnToWorkDateAgreed
                      ? styles.changed
                      : undefined
                  }
                >
                  {formatCheckableItem(
                    aiFormResponse?.form?.returnToWorkStatus
                      ?.returnToWorkDateAgreed,
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Intends to return to work:</td>
                <td>
                  {formatCheckableItem(
                    currentForm.returnToWorkStatus.intendsToReturnToWork,
                  )}
                </td>
                <td
                  className={
                    aiFormResponse?.form?.returnToWorkStatus
                      ?.intendsToReturnToWork !==
                    currentForm.returnToWorkStatus.intendsToReturnToWork
                      ? styles.changed
                      : undefined
                  }
                >
                  {formatCheckableItem(
                    aiFormResponse?.form?.returnToWorkStatus
                      ?.intendsToReturnToWork,
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Unable to return to work:</td>
                <td>
                  {formatCheckableItem(
                    currentForm.returnToWorkStatus.caseManagerReview,
                  )}
                </td>
                <td
                  className={
                    aiFormResponse?.form?.returnToWorkStatus
                      ?.caseManagerReview !==
                    currentForm.returnToWorkStatus.caseManagerReview
                      ? styles.changed
                      : undefined
                  }
                >
                  {formatCheckableItem(
                    aiFormResponse?.form?.returnToWorkStatus?.caseManagerReview,
                  )}
                </td>
              </tr>

              <tr>
                <td>Other</td>
                <td>Date of consultation:</td>
                <td>
                  {dateToLondonNumericDateString(
                    currentForm.dateOfConsultation,
                  )}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.dateOfConsultation,
                    ) !==
                    dateToLondonNumericDateString(
                      currentForm.dateOfConsultation,
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form?.dateOfConsultation ?? undefined,
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>End of care date:</td>
                <td>
                  {dateToLondonNumericDateString(currentForm.endOfCareDate)}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.endOfCareDate,
                    ) !==
                    dateToLondonNumericDateString(currentForm.endOfCareDate)
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form?.endOfCareDate ?? undefined,
                  )}
                </td>
              </tr>

              <tr>
                <td>Expert summary</td>
                <td>Motivation and programme engagement:</td>
                <td>{richTextHtml(currentForm.expertSummary?.motivation)}</td>
                <td
                  className={
                    aiFormResponse?.form?.expertSummary?.motivation !==
                    currentForm.expertSummary.motivation
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.expertSummary?.motivation
                    ? richTextHtml(
                        aiFormResponse?.form.expertSummary?.motivation,
                      )
                    : ''}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Progress against goals and symptom/problem management:</td>
                <td>
                  {richTextHtml(
                    currentForm.expertSummary?.progressAgainstGoals,
                  )}
                </td>
                <td
                  className={
                    aiFormResponse?.form?.expertSummary
                      ?.progressAgainstGoals !==
                    currentForm.expertSummary.progressAgainstGoals
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.expertSummary?.progressAgainstGoals
                    ? richTextHtml(
                        aiFormResponse?.form.expertSummary
                          ?.progressAgainstGoals,
                      )
                    : ''}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Assessment of capability to return back to work:</td>
                <td>
                  {richTextHtml(
                    currentForm.expertSummary
                      ?.assessmentOfCapabilitiesReturnToWork,
                  )}
                </td>
                <td
                  className={
                    aiFormResponse?.form?.expertSummary
                      ?.assessmentOfCapabilitiesReturnToWork !==
                    currentForm.expertSummary
                      .assessmentOfCapabilitiesReturnToWork
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.expertSummary
                    ?.assessmentOfCapabilitiesReturnToWork
                    ? richTextHtml(
                        aiFormResponse?.form.expertSummary
                          ?.assessmentOfCapabilitiesReturnToWork,
                      )
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </FormFieldset>
    </GenerateByAiButton>
  );
};
