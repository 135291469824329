import { array, InferType, object, string } from 'yup';

export const AiCareDocumentGenerateSchema = object().shape({
  appointmentIds: array().of(string().required()).required().min(1),
  documentsPaths: array().of(string().required()),
  prompt: string().nullable().notRequired(),
});

export type AiCareDocumentGenerate = InferType<
  typeof AiCareDocumentGenerateSchema
>;
