import { CSSProperties, MouseEventHandler } from 'react';

export default function MapPinSearchIcon({
  style,
  width,
  onClick,
}: {
  style?: CSSProperties;
  width?: string | number;
  onClick?: MouseEventHandler<SVGSVGElement> | undefined;
}) {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      width={width}
      onClick={onClick}
    >
      <g>
        <path
          fill="currentColor"
          d="M326.41,242.41c1.86-7.68,2.76-15.42,2.76-23.04c0.01-44.05-29.94-83.85-74.56-94.72c-7.68-1.86-15.42-2.76-23.04-2.76
		c-44.05-0.01-83.85,29.94-94.72,74.56c-1.86,7.68-2.76,15.42-2.76,23.04c-0.01,44.05,29.94,83.85,74.56,94.72
		c7.52,1.82,15.23,2.75,22.97,2.75c13.47,0,24.38-10.91,24.39-24.38c0-13.47-10.91-24.38-24.38-24.39c-3.87,0-7.72-0.46-11.48-1.37
		c-22.32-5.36-37.28-25.43-37.29-47.33c0-3.79,0.44-7.65,1.39-11.55c5.36-22.32,25.43-37.28,47.33-37.29
		c3.79,0,7.65,0.44,11.55,1.39c22.32,5.36,37.28,25.43,37.29,47.33c0,3.79-0.44,7.65-1.39,11.55c-3.17,13.09,4.86,26.27,17.95,29.44
		C310.06,263.53,323.24,255.5,326.41,242.41L326.41,242.41z"
        />
        <path
          fill="currentColor"
          d="M231.49,450.68c-6.4-0.02-12.54-2.58-17.06-7.11l-0.01-0.01L110.93,340.11l0,0c-33.37-33.39-49.96-76.92-49.99-120.68
		c0.03-43.76,16.62-87.29,49.99-120.68c33.39-33.37,76.92-49.96,120.68-49.99c43.76,0.03,87.29,16.62,120.68,49.99
		c32.07,32.06,49.99,75.5,49.99,120.67l-0.12,6.53c-0.51,13.46,9.98,24.78,23.43,25.29c13.46,0.51,24.78-9.98,25.29-23.43
		c0.11-2.8,0.16-5.6,0.16-8.4c0-58.08-23.04-113.92-64.27-155.15C343.99,21.47,287.69-0.03,231.62,0
		C175.54-0.03,119.24,21.47,76.46,64.27c-42.8,42.78-64.3,99.08-64.27,155.16c-0.03,56.08,21.47,112.38,64.27,155.16l0,0
		l103.47,103.45l-0.01-0.01c13.63,13.65,32.11,21.35,51.4,21.42c13.47,0.05,24.42-10.83,24.47-24.3
		C255.83,461.68,244.95,450.73,231.49,450.68L231.49,450.68z"
        />
        <path
          fill="currentColor"
          d="M280.38,390.09c0.02,53.87,43.65,97.51,97.52,97.52c53.87-0.02,97.51-43.65,97.52-97.52
		c-0.02-53.87-43.65-97.51-97.52-97.52C324.04,292.59,280.4,336.22,280.38,390.09c0,13.47,10.92,24.38,24.38,24.38
		c13.47,0,24.38-10.92,24.38-24.38c0.05-26.92,21.84-48.71,48.76-48.76c26.92,0.05,48.71,21.84,48.76,48.76
		c-0.05,26.92-21.84,48.71-48.76,48.76c-26.92-0.05-48.71-21.84-48.76-48.76c0-13.47-10.92-24.38-24.38-24.38
		C291.3,365.71,280.38,376.63,280.38,390.09L280.38,390.09z"
        />
        <path
          fill="currentColor"
          d="M414.31,460.97l43.89,43.89c9.52,9.52,24.96,9.52,34.48,0c9.52-9.52,9.52-24.96,0-34.48l-43.89-43.89
		c-9.52-9.52-24.96-9.52-34.48,0C404.78,436.01,404.78,451.45,414.31,460.97"
        />
      </g>
    </svg>
  );
}
