import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikInputText,
  FormikTextArea,
  isFormikValid,
  Spinner,
} from '@percihealth/react';
import { useFormik, FormikProvider } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Profile, ProfileSchema } from '@packages/core-shared';
import { ProfileApiRepository } from '../../api';
import { useFirebase } from '../../context';
import { useAuth } from '@percihealth/react-auth';

export default function ProfilePage() {
  const { auth } = useFirebase();
  const { user, isInitializing } = useAuth();
  const profileApiRepository = useMemo(
    () => new ProfileApiRepository(auth),
    [auth],
  );

  const [isLoading, setIsLoading] = useState(true);

  const [profile, setProfile] = useState<Profile | null>(null);
  useEffect(() => {
    if (!user) {
      return;
    }
    const load = async () => {
      try {
        setResponseError(null);
        setIsLoading(true);
        const result = await profileApiRepository.get();
        setProfile(result);
      } catch (error) {
        console.error(error);
        setResponseError((error as Error).message);
        setProfile(null);
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, [user, profileApiRepository]);

  const [responseError, setResponseError] = useState<string | null>(null);

  const profileHandleOnsubmit = async (values: any, actions: any) => {
    if (!isFormikValid(formik)) {
      return;
    }
    setResponseError(null);

    actions.setSubmitting(true);
    try {
      await profileApiRepository.update(values);
    } catch (error) {
      console.error(error);
      setResponseError((error as Error).message);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const initialValues: Profile = useMemo(() => {
    return (
      profile ?? {
        firstName: '',
        lastName: '',
        slackId: '',
        writingStyle: '',
      }
    );
  }, [profile]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: ProfileSchema.isValidSync(initialValues),
    initialValues: initialValues,
    validationSchema: ProfileSchema,
    onSubmit: profileHandleOnsubmit,
  });

  if (isInitializing || isLoading) {
    return <Spinner />;
  }

  if (!user) {
    return <></>;
  }

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <FormFieldset title="Display">
          <FormikInputText
            label="First name"
            name="firstName"
            value={formik.values.firstName}
          />
          <FormikInputText
            label="Last name"
            name="lastName"
            value={formik.values.lastName}
          />
        </FormFieldset>
        <FormFieldset title="AI">
          <FormikTextArea
            className="full-width"
            label="Writing style"
            name="writingStyle"
            value={formik.values.writingStyle}
          />
        </FormFieldset>
        <FormFieldset title="Slack">
          <FormikInputText
            label="Slack ID"
            name="slackId"
            value={formik.values.slackId}
          />
        </FormFieldset>
        {responseError && (
          <div>
            <AlertError>{responseError}</AlertError>
            <br />
          </div>
        )}

        <Button type="submit" submitting={formik.isSubmitting}>
          Save
        </Button>
      </Form>
    </FormikProvider>
  );
}
