import { CareDocumentsApiRepository } from '@packages/web-shared/api';
import { useCallback, useMemo, useState } from 'react';
import {
  AiCareDocumentGenerate,
  PcpAiGenerated,
  PcpAiGeneratedResponse,
  PcpData,
  dateToLondonNumericDateString,
} from '@packages/core-shared';
import richTextHtml from '../components/richTextHtml';
import { useFirebase } from '@packages/web-shared';
import { ReferralDocumentsUsedList } from '../components/ReferralDocumentsUsedList';
import { GenerateByAiButton } from '../components/GenerateByAiButton';
import styles from '../components/GenerateByAiButton/GenerateByAiButton.module.css';
import { FormFieldset } from '@percihealth/react';

interface Props {
  caseId: string;
  referralId: string | null;
  currentForm: PcpData;
  onChange: (data: PcpAiGenerated) => void;
}

export const PcpFormGenerateByAiButton = ({
  caseId,
  referralId,
  currentForm,
  onChange,
}: Props) => {
  const { auth } = useFirebase();

  const [aiFormResponse, setAiFormResponse] =
    useState<PcpAiGeneratedResponse | null>(null);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const generateAiForm = useCallback(
    async (data: AiCareDocumentGenerate) => {
      const result = await docsApiRepository.generateAiPcpForm(caseId, data);
      setAiFormResponse(result);
    },
    [docsApiRepository],
  );

  const formatPatientService = useCallback(
    (ps: {
      service: {
        id: string;
        name: string;
        healee_ref: string;
      };
      concerns?: string[] | undefined;
    }) => {
      return `- ${ps.service.name} ${ps.concerns?.length ? ` - ${ps.concerns?.join(', ') ?? ''}` : ''}`;
    },
    [],
  );

  return (
    <GenerateByAiButton
      caseId={caseId}
      referralId={referralId}
      generateAiForm={generateAiForm}
      onFillForm={() => {
        onChange(aiFormResponse!.form!);
      }}
    >
      <h2 className={styles.header}>AI generated form</h2>

      <FormFieldset title="Documents" className={styles.fieldset}>
        <ReferralDocumentsUsedList
          appointments={aiFormResponse?.appointmentsWithTranscriptions ?? []}
          documents={aiFormResponse?.documentsUsed ?? []}
        />
      </FormFieldset>

      <FormFieldset title="Form changes" className={styles.fieldset}>
        <div className="full-width">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Field</th>
                <th>Current form</th>
                <th>Incoming AI data</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>I am:</td>
                <td>{currentForm.patient.iam}</td>
                <td
                  className={
                    aiFormResponse?.form?.patient?.iam !==
                    currentForm.patient.iam
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form?.patient?.iam}
                </td>
              </tr>

              <tr>
                <td>Date of consultation:</td>
                <td>
                  {dateToLondonNumericDateString(
                    currentForm.dateOfConsultation,
                  )}
                </td>
                <td
                  className={
                    aiFormResponse?.form?.dateOfConsultation !==
                    currentForm.dateOfConsultation
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form?.dateOfConsultation ?? undefined,
                  )}
                </td>
              </tr>

              <tr>
                <td>Patient services:</td>
                <td>
                  {currentForm?.patientServices?.map((ps) => (
                    <div key={formatPatientService(ps)}>
                      {formatPatientService(ps)}
                    </div>
                  ))}
                </td>
                <td
                  className={
                    JSON.stringify(
                      aiFormResponse?.form?.patientServices?.map(
                        formatPatientService,
                      ),
                    ) !==
                    JSON.stringify(
                      currentForm.patientServices?.map(formatPatientService),
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form?.patientServices?.map((ps) => (
                    <div key={formatPatientService(ps)}>
                      {formatPatientService(ps)}
                    </div>
                  ))}
                </td>
              </tr>

              <tr>
                <td>Notes:</td>
                <td>{richTextHtml(currentForm?.notes)}</td>
                <td
                  className={
                    aiFormResponse?.form?.notes !== currentForm.notes
                      ? styles.changed
                      : undefined
                  }
                >
                  {richTextHtml(aiFormResponse?.form?.notes ?? '')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </FormFieldset>
    </GenerateByAiButton>
  );
};
