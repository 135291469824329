import { Appointment, dateToLondonLongDateString } from '@packages/core-shared';
import styles from './ReferralDocumentsUsedList.module.css';

export const ReferralDocumentsUsedList = ({
  documents,
  appointments,
}: {
  documents: (string | undefined)[];
  appointments: Appointment[];
}) => {
  return (
    <>
      <div className="full-width">
        The form is generated based on the following appointments and documents
      </div>
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Apt.ID</th>
              <th>Date</th>
              <th>Doctor</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((apt) => (
              <tr>
                <td>{apt.id}</td>
                <td>{dateToLondonLongDateString(apt.date)}</td>
                <td>{apt.doctor}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Document Name</th>
            </tr>
          </thead>

          <tbody>
            {documents.map((document) => (
              <tr>
                <td>{document}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
