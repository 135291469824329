import { emailValidation } from '@percihealth/core-js';
import { InferType, boolean, string } from 'yup';
import { IObject } from './IObject';
import { ProfileSchema } from './ProfileSchema';

export const InternalUserDataSchema = ProfileSchema.shape({
  email: emailValidation.required('User email is required'),
  // roles
  admin: boolean(),
  roleEditor: boolean(),
  expert: boolean(),

  photoUrl: string(),
});

export type InternalUserData = InferType<typeof InternalUserDataSchema>;
export type InternalUser = IObject.HasIdTimestamped<InternalUserData>;
