export enum CancerStatus {
  livingWith = 'livingWith',
  livingBeyondCancer = 'livingBeyondCancer',
  carer = 'carer',
  cancerPrevention = 'cancerPrevention',
  lineManagerSupportingEmployee = 'lineManagerSupportingEmployee',
}

export const cancerStatusToString = (cancerStatus: CancerStatus) => {
  switch (cancerStatus) {
    case CancerStatus.livingWith:
      return 'Living with cancer';
    case CancerStatus.carer:
      return 'Supporting someone with cancer';
    case CancerStatus.livingBeyondCancer:
      return 'Living beyond cancer';
    case CancerStatus.cancerPrevention:
      return 'Interested in cancer prevention';
    case CancerStatus.lineManagerSupportingEmployee:
      return 'Line manager supporting employee';
    default:
      return '';
  }
};
