import { Tooltip } from '@percihealth/react';
import { FormikState } from 'formik';
import { hasStateErrors } from '../helpers/formik';

export const FormErrorsTooltip = ({
  formik,
  children,
}: {
  formik: FormikState<any>;
  children: React.ReactNode;
}) => {
  // formik.isValid can show false while formik.errors is empty (not yet validated)
  if (!hasStateErrors(formik)) {
    return children;
  }

  return (
    <div>
      <Tooltip
        contentStyle={{ width: '80%' }}
        open={true}
        defaultOpen
        closeOnDocumentClick={false}
        trigger={<div>{children}</div>}
      >
        <div>
          <b>Form JSON errors:</b>
        </div>
        <div>
          <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
        </div>
      </Tooltip>
    </div>
  );
};
