import { CareDocumentsApiRepository } from '@packages/web-shared/api';
import { useCallback, useMemo, useState } from 'react';
import {
  InitialAssessmentSummaryAiGeneratedResponse,
  InitialAssessmentSummaryData,
  InitialAssessmentSummaryAiGenerated,
  dateToLondonNumericDateString,
  AiCareDocumentGenerate,
} from '@packages/core-shared';
import richTextHtml from '../components/richTextHtml';
import { useFirebase } from '@packages/web-shared';
import { ReferralDocumentsUsedList } from '../components/ReferralDocumentsUsedList';
import { GenerateByAiButton } from '../components/GenerateByAiButton';
import styles from '../components/GenerateByAiButton/GenerateByAiButton.module.css';
import { FormFieldset } from '@percihealth/react';

interface Props {
  caseId: string;
  referralId: string | null;
  currentForm: InitialAssessmentSummaryData;
  onChange: (data: InitialAssessmentSummaryAiGenerated) => void;
}

export const InitialAssessmentGenerateByAiButton = ({
  caseId,
  referralId,
  currentForm,
  onChange,
}: Props) => {
  const { auth } = useFirebase();

  const [aiFormResponse, setAiFormResponse] =
    useState<InitialAssessmentSummaryAiGeneratedResponse | null>(null);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const generateAiForm = useCallback(
    async (data: AiCareDocumentGenerate) => {
      const result =
        await docsApiRepository.generateAiInitialAssessmentSummaryForm(
          caseId,
          data,
        );
      setAiFormResponse(result);
    },
    [docsApiRepository],
  );

  const formatPlanToOvercomeChallenges = useCallback(
    (plan: {
      challenge?: string | null | undefined;
      recommendations?:
        | {
            recommendation?: string | null | undefined;
            timeframe?: string | null | undefined;
          }[]
        | null
        | undefined;
      progress?: string | null | undefined;
    }) => {
      return `- ${plan.challenge} ${plan.progress ? ` - ${plan.recommendations?.join(', ') ?? ''}` : ''}`;
    },
    [],
  );

  return (
    <GenerateByAiButton
      caseId={caseId}
      referralId={referralId}
      generateAiForm={generateAiForm}
      onFillForm={() => {
        onChange(aiFormResponse!.form!);
      }}
    >
      <h2 className={styles.header}>AI generated form</h2>

      <FormFieldset title="Documents" className={styles.fieldset}>
        <ReferralDocumentsUsedList
          appointments={aiFormResponse?.appointmentsWithTranscriptions ?? []}
          documents={aiFormResponse?.documentsUsed ?? []}
        />
      </FormFieldset>

      <FormFieldset title="Form changes" className={styles.fieldset}>
        <div className="full-width">
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Section</th>
                <th>Field</th>
                <th>Current form</th>
                <th>Incoming AI data</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Patient</td>
                <td>Cancer type:</td>
                <td>{currentForm.patient.cancerType}</td>
                <td
                  className={
                    aiFormResponse?.form?.patient?.cancerType !==
                    currentForm.patient.cancerType
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.cancerType}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Date of diagnosis:</td>
                <td>
                  {dateToLondonNumericDateString(
                    currentForm.patient.dateOfDiagnosis,
                  )}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.patient?.dateOfDiagnosis,
                    ) !==
                    dateToLondonNumericDateString(
                      currentForm.patient.dateOfDiagnosis,
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form.patient?.dateOfDiagnosis,
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>DOB:</td>
                <td>
                  {dateToLondonNumericDateString(currentForm.patient.dob)}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.patient?.dob,
                    ) !== dateToLondonNumericDateString(currentForm.patient.dob)
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form.patient?.dob,
                  )}
                </td>
              </tr>

              <tr>
                <td>Work</td>
                <td>Date signed-off work:</td>
                <td>
                  {dateToLondonNumericDateString(
                    currentForm.patient.work.dateSignedOff,
                  )}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.patient?.work?.dateSignedOff,
                    ) !==
                    dateToLondonNumericDateString(
                      currentForm.patient.work?.dateSignedOff,
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form.patient?.work?.dateSignedOff,
                  )}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Job title:</td>
                <td>{currentForm.patient.work.jobTitle}</td>
                <td
                  className={
                    aiFormResponse?.form?.patient?.work?.jobTitle !==
                    currentForm.patient.work?.jobTitle
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work?.jobTitle}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Work pattern:</td>
                <td>{currentForm.patient.work.workPattern}</td>
                <td
                  className={
                    (aiFormResponse?.form?.patient?.work?.workPattern ?? '') !==
                    (currentForm.patient.work.workPattern ?? '')
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work?.workPattern}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Hide hoping to return to work:</td>
                <td>
                  {currentForm.patient.work.hideHopingToReturnToWorkIn
                    ? 'Yes'
                    : 'No'}
                </td>
                <td
                  className={
                    (aiFormResponse?.form?.patient?.work
                      ?.hideHopingToReturnToWorkIn ?? false) !==
                    (currentForm.patient.work?.hideHopingToReturnToWorkIn ??
                      false)
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work
                    ?.hideHopingToReturnToWorkIn
                    ? 'Yes'
                    : 'No'}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Hoping to return to work in:</td>
                <td>{currentForm.patient.work.hopingToReturnToWorkIn}</td>
                <td
                  className={
                    (aiFormResponse?.form?.patient?.work
                      ?.hopingToReturnToWorkIn ?? null) !==
                    (currentForm.patient.work?.hopingToReturnToWorkIn ?? null)
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work?.hopingToReturnToWorkIn}
                </td>
              </tr>

              <tr>
                <td>Treatment</td>
                <td>Treatment to date:</td>
                <td>{currentForm.treatmentDetails.treatment}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.treatment !==
                    currentForm.treatmentDetails.treatment
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.treatment}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Ongoing treatment plan:</td>
                <td>{currentForm.treatmentDetails.plan}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.plan !==
                    currentForm.treatmentDetails.plan
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.plan}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Expected treatment end date:</td>
                <td>{currentForm.treatmentDetails.expectedEndDate}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.expectedEndDate !==
                    currentForm.treatmentDetails.expectedEndDate
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.expectedEndDate}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Other medical relevant history:</td>
                <td>{currentForm.treatmentDetails.other}</td>
                <td
                  className={
                    aiFormResponse?.form?.treatmentDetails?.other !==
                    currentForm.treatmentDetails.other
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.treatmentDetails?.other}
                </td>
              </tr>

              <tr>
                <td>Other</td>
                <td>Date of consultation:</td>
                <td>
                  {dateToLondonNumericDateString(
                    currentForm.dateOfConsultation,
                  )}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.dateOfConsultation,
                    ) !==
                    dateToLondonNumericDateString(
                      currentForm.dateOfConsultation,
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form?.dateOfConsultation ?? undefined,
                  )}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>First appointment date with CNS:</td>
                <td>
                  {dateToLondonNumericDateString(currentForm.perciStartDate)}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.perciStartDate,
                    ) !==
                    dateToLondonNumericDateString(currentForm.perciStartDate)
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form?.perciStartDate ?? undefined,
                  )}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Plan to overcome challenges:</td>
                <td>
                  {currentForm?.planToOvercomeChallenges?.map((plan) => (
                    <div key={formatPlanToOvercomeChallenges(plan)}>
                      {formatPlanToOvercomeChallenges(plan)}
                    </div>
                  ))}
                </td>
                <td
                  className={
                    JSON.stringify(
                      aiFormResponse?.form?.planToOvercomeChallenges?.map(
                        formatPlanToOvercomeChallenges,
                      ),
                    ) !==
                    JSON.stringify(
                      currentForm.planToOvercomeChallenges?.map(
                        formatPlanToOvercomeChallenges,
                      ),
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form?.planToOvercomeChallenges?.map(
                    (plan) => (
                      <div key={formatPlanToOvercomeChallenges(plan)}>
                        {formatPlanToOvercomeChallenges(plan)}
                      </div>
                    ),
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Expert summary:</td>
                <td>{richTextHtml(currentForm.expertSummary)}</td>
                <td
                  className={
                    aiFormResponse?.form?.expertSummary !==
                    currentForm.expertSummary
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.expertSummary
                    ? richTextHtml(aiFormResponse?.form.expertSummary)
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </FormFieldset>
    </GenerateByAiButton>
  );
};
