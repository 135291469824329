import { object, string, array, InferType } from 'yup';
import { CareDocumentOvercomeChallengeRecommendationSchema } from './CareDocumentOvercomeChallengeRecommendationSchema';

export const CareDocumentOvercomeChallengeSchema = object().shape({
  challenge: string().required('Challenge is required'),
  recommendations: array().of(
    CareDocumentOvercomeChallengeRecommendationSchema.required(),
  ),
  progress: string().nullable().optional(),
});

export type CareDocumentOvercomeChallenge = InferType<
  typeof CareDocumentOvercomeChallengeSchema
>;
