import styles from './SignedInHeaderSection.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@percihealth/react-auth';
import { useFirebase } from '../../../../context';
import DropdownIcon from '../../../icons/DropdownIcon';
import { useState } from 'react';

export default function SignedInHeaderSection() {
  const { user } = useAuth();
  const { auth } = useFirebase();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);

  if (!user) return <></>;

  return (
    <div onBlur={() => setExpanded(false)} tabIndex={0}>
      <div className={styles['toggle']} onClick={() => setExpanded(!expanded)}>
        {user.displayName} <DropdownIcon className={styles.icon} />
      </div>

      {expanded && (
        <div className={styles.menus}>
          <hr className={styles.hr} />
          <Menu>
            <a
              onClick={() => {
                setExpanded(false);
                navigate('/Profile');
              }}
            >
              Profile
            </a>
          </Menu>
          <Menu>
            <a
              onClick={() => {
                auth.signOut();
                setExpanded(false);
                navigate('/login');
              }}
            >
              Sign-out
            </a>
          </Menu>
        </div>
      )}
    </div>
  );
}

const Menu = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.menu}>{children}</div>;
};
