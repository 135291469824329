import { useEventDispatch } from '@packages/web-shared/hooks/eventEmitter/useEventDispatch';
import { useCallback } from 'react';
import {
  EbpMedicalHistoryField,
  getColorForField,
} from './EbpMedicalHistoryField';
import { MedicalHistoryLocateEvents } from './MedicalHistoryLocateEvents';
import MapPinSearchIcon from './MapPinSearchIcon';

export default function FieldWithLocator({
  children,
  type,
  docPath,
  parsedValue,
}: {
  children: React.ReactNode;
  type: EbpMedicalHistoryField;
  docPath?: string;
  parsedValue?: any;
}) {
  const dispatchEvent = useEventDispatch();
  const locate = useCallback(() => {
    dispatchEvent(MedicalHistoryLocateEvents.ValidationLocateDocumentEvent, {
      field: type,
      docPath,
    });
  }, [dispatchEvent]);

  return (
    <div
      style={{
        minWidth: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        paddingRight: '0',
      }}
    >
      {children}
      {parsedValue && parsedValue !== '' ? (
        <MapPinSearchIcon
          width={36}
          style={{
            color: getColorForField(type),
            alignSelf: 'end',
            margin: '0 6px 8px 6px',
            cursor: 'pointer',
          }}
          onClick={locate}
        />
      ) : (
        <div style={{ width: 36, margin: '0 6px 8px 6px' }} />
      )}
    </div>
  );
}
