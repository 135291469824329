import {
  dateTimeToLocalDateTimeString,
  HealeePatientMedicalHistory,
  HealeeMedicalHistoryPatientSchema,
  EbpMedicalHistoryPatientAnalyzeResult,
  HealeeGender,
  healeeGenderToString,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  FormFieldset,
  FormikDatepicker,
  FormikDropdownSearch,
  FormikInputText,
} from '@percihealth/react';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { EbpMedicalHistoryField } from './EbpMedicalHistoryField';
import styles from './MedicalHistoryForm.module.css';
import FieldWithLocator from './FieldWithLocator';
import { CasesApiRepository } from '@packages/web-shared/api';
import { useFirebase } from '@packages/web-shared';

const HealeeGenderOptions = [
  { value: '', name: '-' },
  ...Object.values(HealeeGender)
    .filter((g) => typeof g !== 'string')
    .map((gender) => ({
      value: gender.toString(),
      name: healeeGenderToString(gender as HealeeGender),
    })),
];

export default function MedicalHistoryForm({
  caseId,
  medicalHistoryAnalyzeResult,
  initialValues,
  readOnly,
}: {
  caseId: string;
  medicalHistoryAnalyzeResult: EbpMedicalHistoryPatientAnalyzeResult | null;
  initialValues: HealeePatientMedicalHistory;
  readOnly?: boolean;
}) {
  const { auth } = useFirebase();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );

  const handleOnSubmit = async (
    values: HealeePatientMedicalHistory,
    actions: FormikHelpers<HealeePatientMedicalHistory>,
  ) => {
    try {
      actions.setSubmitting(true);
      setErrorMessage(null);

      // correct gender type to enum
      if (typeof values.gender === 'string') {
        values.gender =
          values.gender !== '' ? Number(values.gender) : undefined;
      }

      await casesApiRepository.saveMedicalHistory(caseId, values);

      window.location.reload();

      // Send
    } catch (error: unknown) {
      let errorMsg = '';
      console.error(error);

      if (error instanceof Error) {
        errorMsg = error.message;
      } else {
        errorMsg = JSON.stringify(error);
      }
      console.error(errorMsg);
      setErrorMessage(errorMsg);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid:
      HealeeMedicalHistoryPatientSchema.isValidSync(initialValues),
    initialValues: initialValues,
    validationSchema: HealeeMedicalHistoryPatientSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [caseId]);

  return (
    <FormikProvider value={formik}>
      <div style={{ paddingRight: '64px', overflowY: 'scroll' }}>
        {initialValues.at && (
          <div style={{ marginBottom: '16px' }}>
            Sent to EHR: {dateTimeToLocalDateTimeString(initialValues.at)}
          </div>
        )}
        <Form>
          <FormFieldset title="Member">
            <FieldWithLocator
              type={EbpMedicalHistoryField.MemberAddress}
              docPath={medicalHistoryAnalyzeResult?.patient?.address?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.patient?.address?.value}
            >
              <FormikInputText
                className="full-width"
                name="address.country"
                label="Country"
                readOnly={readOnly}
                value={formik.values.address?.country ?? ''}
              />
            </FieldWithLocator>
            <FormikInputText
              className={styles.city}
              name="address.city"
              label="City"
              readOnly={readOnly}
              value={formik.values.address?.city ?? ''}
            />
            <FormikInputText
              name="address.postCode"
              label="Postal code"
              value={formik.values.address?.postCode ?? ''}
              readOnly={readOnly}
              className={styles['postal-code']}
            />
            <FormikInputText
              className="full-width"
              name="address.line1"
              label="Line 1"
              readOnly={readOnly}
              value={formik.values.address?.line1 ?? ''}
            />
            <FormikInputText
              className="full-width"
              name="address.line2"
              label="Line 2"
              readOnly={readOnly}
              value={formik.values.address?.line2 ?? ''}
            />
            <hr />
            <FieldWithLocator
              type={EbpMedicalHistoryField.MemberDob}
              docPath={
                medicalHistoryAnalyzeResult?.patient?.dateOfBirth?.docPath
              }
              parsedValue={
                medicalHistoryAnalyzeResult?.patient?.dateOfBirth?.value
              }
            >
              <FormikDatepicker
                className="full-width"
                name="dateOfBirth"
                label="DOB"
                readOnly={readOnly}
                value={formik.values.dateOfBirth ?? null}
              />
            </FieldWithLocator>

            <FieldWithLocator
              type={EbpMedicalHistoryField.MemberGender}
              docPath={medicalHistoryAnalyzeResult?.patient?.gender?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.patient?.gender?.value}
            >
              <FormikDropdownSearch
                name="gender"
                label="Gender"
                multiple={false}
                value={formik.values.gender?.toString() ?? ''}
                options={HealeeGenderOptions}
              />
            </FieldWithLocator>
            <FieldWithLocator
              type={EbpMedicalHistoryField.MemberHeight}
              docPath={medicalHistoryAnalyzeResult?.patient?.height?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.patient?.height?.value}
            >
              <FormikInputText
                name="height"
                label="Height (IN)"
                readOnly={readOnly}
                value={formik.values.height?.toString() ?? ''}
                onBlur={(e) => {
                  let v = e.target.value;
                  v = v.replace(/[^0-9.,]/g, '');

                  const res = Number(v);
                  formik.setFieldValue(
                    'height',
                    v !== '' && !isNaN(res) ? res : undefined,
                  );
                }}
              />
            </FieldWithLocator>
            <FieldWithLocator
              type={EbpMedicalHistoryField.MemberWeight}
              docPath={medicalHistoryAnalyzeResult?.patient?.weight?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.patient?.weight?.value}
            >
              <FormikInputText
                name="weight"
                label="Weight (LB)"
                readOnly={readOnly}
                value={formik.values.weight?.toString() ?? ''}
                onBlur={(e) => {
                  let v = e.target.value;
                  v = v.replace(/[^0-9.,]/g, '');
                  const res = Number(v);
                  formik.setFieldValue(
                    'weight',
                    v !== '' && !isNaN(res) ? res : undefined,
                  );
                }}
              />
            </FieldWithLocator>
            <FieldWithLocator
              type={EbpMedicalHistoryField.MemberEthnicity}
              docPath={medicalHistoryAnalyzeResult?.patient?.ethnicity?.docPath}
              parsedValue={
                medicalHistoryAnalyzeResult?.patient?.ethnicity?.value
              }
            >
              <FormikInputText
                name="ethnicity"
                label="Ethnicity"
                readOnly={readOnly}
                value={formik.values.ethnicity ?? ''}
              />
            </FieldWithLocator>
            <hr />
            <FieldWithLocator
              type={EbpMedicalHistoryField.CancerType}
              docPath={medicalHistoryAnalyzeResult?.cancerType?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.cancerType?.value}
            >
              <FormikInputText
                className="full-width"
                name="cancerType"
                label="Cancer type"
                readOnly={readOnly}
                value={formik.values.cancerType ?? ''}
              />
            </FieldWithLocator>
            <FieldWithLocator
              type={EbpMedicalHistoryField.CancerStage}
              docPath={medicalHistoryAnalyzeResult?.cancerStage?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.cancerStage?.value}
            >
              <FormikInputText
                className="full-width"
                name="cancerStage"
                label="Cancer stage"
                readOnly={readOnly}
                value={formik.values.cancerStage ?? ''}
              />
            </FieldWithLocator>
            <FieldWithLocator
              type={EbpMedicalHistoryField.CancerYearOfDiagnosis}
              docPath={
                medicalHistoryAnalyzeResult?.cancerYearOfDiagnosis?.docPath
              }
              parsedValue={
                medicalHistoryAnalyzeResult?.cancerYearOfDiagnosis?.value
              }
            >
              <FormikInputText
                className="full-width"
                name="cancerYearOfDiagnosis"
                label="Cancer year of diagnosis (YYYY)"
                readOnly={readOnly}
                value={formik.values.cancerYearOfDiagnosis?.toString() ?? ''}
                onChange={(e) => {
                  let v = e.target.value;
                  v = v.replace(/[^0-9]/g, '');
                  if (v.length > 4) {
                    v = v.slice(0, 4);
                  }

                  formik.setFieldValue(
                    'cancerYearOfDiagnosis',
                    v !== '' ? Number(v) : undefined,
                  );
                }}
              />
            </FieldWithLocator>
          </FormFieldset>

          <FormFieldset title="General Practice">
            <FieldWithLocator
              type={EbpMedicalHistoryField.GeneralPractice}
              docPath={medicalHistoryAnalyzeResult?.gpPracticeName?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.gpPracticeName?.value}
            >
              <FormikInputText
                className="full-width"
                name="gpPracticeName"
                label="General Practice title"
                readOnly={readOnly}
                value={formik.values.gpPracticeName ?? ''}
              />
            </FieldWithLocator>
            <hr />
            <FieldWithLocator
              type={EbpMedicalHistoryField.GeneralPractitioner}
              docPath={medicalHistoryAnalyzeResult?.gpName?.docPath}
              parsedValue={medicalHistoryAnalyzeResult?.gpName?.value}
            >
              <FormikInputText
                className="full-width"
                name="gpName"
                label="General Practitioner full name"
                readOnly={readOnly}
                value={formik.values.gpName ?? ''}
              />
            </FieldWithLocator>
          </FormFieldset>

          {errorMessage && (
            <div style={{ marginBottom: '16px' }}>
              <AlertError>{errorMessage}</AlertError>
            </div>
          )}
          <Button
            className={styles.submit}
            type="submit"
            disabled={!formik.isValid || readOnly}
            submitting={formik.isSubmitting}
          >
            Send to EHR
          </Button>
        </Form>
      </div>
    </FormikProvider>
  );
}
