import { InferType, bool, date, object, string } from 'yup';

export const CareDocumentWorkDetailsSchema = object().shape({
  jobTitle: string().required('Job title is required'),
  dateSignedOff: date().required('Date signed off is required'),
  workPattern: string().required('Work pattern is required'),
  hideHopingToReturnToWorkIn: bool().required(),
  hopingToReturnToWorkIn: string().when('hideHopingToReturnToWorkIn', {
    is: false,
    then: (schema) => schema.required('Hoping to return to work is required'),
  }),
});

export type CareDocumentWorkDetails = InferType<
  typeof CareDocumentWorkDetailsSchema
>;
