import { InferType, object, string } from 'yup';

export const ProfileSchema = object().shape({
  firstName: string().max(50),
  lastName: string().max(50),
  slackId: string().max(50),
  writingStyle: string().max(2028),
});

export type Profile = InferType<typeof ProfileSchema>;
