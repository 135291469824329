import { useEffect, useMemo, useState } from 'react';
import {
  EbpLandgCase,
  HealeePatientMedicalHistory,
  OrganizationId,
  EbpMedicalHistoryPatientAnalyzeResult,
  ReferralLandg,
} from '@packages/core-shared';
import MedicalHistoryForm from './MedicalHistoryForm';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useParams } from 'react-router-dom';
import { AlertError, Spinner } from '@percihealth/react';
import { EventEmitter } from '@packages/web-shared/hooks/eventEmitter/EventEmitter';
import PdfTabs from './PdfTabs';

const defaultMedicalHistory: HealeePatientMedicalHistory = {
  cancerType: '',
  cancerStage: '',

  gpPracticeName: '',
  gpName: '',

  address: {
    country: '',
    city: '',
    line1: '',
    line2: '',
    postCode: '',
  },

  ethnicity: '',
};

export default function MedicalHistoryValidationPage() {
  const params = useParams();
  const caseId = params?.caseId ?? null;

  if (!caseId) {
    return <div>Case ID (email) should be specified</div>;
  }

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useGetFirestoreDocument<EbpLandgCase>('cases', caseId);

  const { record: referral } = useGetFirestoreDocument<ReferralLandg>(
    'referrals',
    ebpCase?.referralId,
  );

  const [healeeMedicalHistory, setHealeeMedicalHistory] =
    useState<HealeePatientMedicalHistory>(defaultMedicalHistory);

  const medicalHistoryParsingLoader =
    useGetFirestoreDocument<EbpMedicalHistoryPatientAnalyzeResult>(
      'medicalHistoryParsing',
      caseId,
    );

  const medicalHistoryAnalyzeResult = useMemo(
    () => medicalHistoryParsingLoader.record,
    [medicalHistoryParsingLoader.record],
  );

  useEffect(() => {
    let medHistory: HealeePatientMedicalHistory = defaultMedicalHistory;
    const parsed = medicalHistoryAnalyzeResult;

    if (ebpCase?.patient?.medicalHistory?.sentToHealee) {
      medHistory = ebpCase.patient.medicalHistory.sentToHealee;
    } else if (parsed) {
      medHistory = {
        address: {
          country: parsed.patient?.address?.value?.country ?? '',
          city: parsed.patient?.address?.value?.city ?? '',
          line1: parsed.patient?.address?.value?.line1 ?? '',
          line2: parsed.patient?.address?.value?.line2 ?? '',
          postCode: parsed.patient?.address?.value?.postCode ?? '',
        },
        dateOfBirth: parsed.patient?.dateOfBirth?.value ?? undefined,
        gender: parsed.patient?.gender?.value,
        height: parsed.patient?.height?.value,
        weight: parsed.patient?.weight?.value,
        ethnicity: parsed.patient?.ethnicity?.value ?? '',

        cancerType: parsed.cancerType?.value ?? '',
        cancerStage: parsed.cancerStage?.value ?? '',
        cancerYearOfDiagnosis: parsed.cancerYearOfDiagnosis?.value,

        gpPracticeName: parsed.gpPracticeName?.value ?? '',
        gpName: parsed.gpName?.value ?? '',
      };
    }

    setHealeeMedicalHistory(medHistory);
  }, [defaultMedicalHistory, ebpCase, medicalHistoryAnalyzeResult]);

  if (medicalHistoryParsingLoader.errorMessage) {
    return <AlertError>{medicalHistoryParsingLoader.errorMessage}</AlertError>;
  }

  if (caseLoadingErrorMessage) {
    return <AlertError>{caseLoadingErrorMessage}</AlertError>;
  }

  if (medicalHistoryParsingLoader.loading || caseLoading) {
    return <Spinner />;
  }

  if (ebpCase?.organization.id !== OrganizationId.landg) {
    return (
      <div>
        Only L&G is supported, this case is for: {ebpCase?.organization.id}
      </div>
    );
  }

  return (
    <EventEmitter>
      <div
        style={{
          maxWidth: '1400px',
          margin: '0 auto',
          height: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 2fr',
          gap: '20px',
        }}
      >
        <MedicalHistoryForm
          caseId={caseId}
          initialValues={healeeMedicalHistory}
          medicalHistoryAnalyzeResult={medicalHistoryAnalyzeResult}
          readOnly={!!ebpCase?.patient?.medicalHistory?.sentToHealee}
        />
        {ebpCase.referralId && (
          <PdfTabs
            referralId={ebpCase.referralId}
            referralDocuments={referral?.documents}
            medicalHistoryAnalyzeResult={medicalHistoryParsingLoader.record}
          />
        )}
      </div>
    </EventEmitter>
  );
}
