export enum EbpMedicalHistoryField {
  MemberDob = 'MemberDob',
  MemberAddress = 'MemberAddress',
  MemberGender = 'MemberGender',
  MemberHeight = 'MemberHeight',
  MemberWeight = 'MemberWeight',
  MemberEthnicity = 'MemberEthnicity',

  GeneralPractice = 'GeneralPractice',
  GeneralPractitioner = 'GeneralPractitioner',

  CancerType = 'CancerType',
  CancerStage = 'CancerStage',
  CancerYearOfDiagnosis = 'CancerYearOfDiagnosis',
}

export const getColorForField = (field: EbpMedicalHistoryField) => {
  switch (field) {
    // Use hard-coded values so it is working in context 2d draw
    case EbpMedicalHistoryField.MemberAddress:
      return '#5f908f'; //'var(--global-color-primary-steel-teal)';
    case EbpMedicalHistoryField.MemberDob:
      return '#4EC95D';
    case EbpMedicalHistoryField.MemberGender:
      return '#ff6bd4';
    case EbpMedicalHistoryField.MemberHeight:
      return '#04ff98';
    case EbpMedicalHistoryField.MemberWeight:
      return '#22fff5';
    case EbpMedicalHistoryField.MemberEthnicity:
      return '#075bf5';

    case EbpMedicalHistoryField.CancerType:
      return '#134855'; //'var(--global-color-secondary-calm)';
    case EbpMedicalHistoryField.CancerStage:
      return '#ffc36b';
    case EbpMedicalHistoryField.CancerYearOfDiagnosis:
      return '#ff046e';
    case EbpMedicalHistoryField.GeneralPractice:
      return '#58A4B0';
    case EbpMedicalHistoryField.GeneralPractitioner:
      return '#CE5947';
    default:
      return 'black';
  }
};
