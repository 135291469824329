export enum HealeeGender {
  Male = 1,
  Female = 2,
  PreferNotToSay = 3,
}

export const healeeGenderToString = (gender?: HealeeGender): string => {
  if (!gender) {
    return '';
  }

  switch (gender) {
    case HealeeGender.Male:
      return 'Male';
    case HealeeGender.Female:
      return 'Female';
    case HealeeGender.PreferNotToSay:
      return 'Prefer not to say';
    default:
      return '';
  }
};
