import { CareDocumentsApiRepository } from '@packages/web-shared/api';
import { useCallback, useMemo, useState } from 'react';
import {
  InterimCareSummaryAiGeneratedResponse,
  InterimCareSummaryData,
  InterimCareSummaryAiGenerated,
  dateToLondonNumericDateString,
  AiCareDocumentGenerate,
} from '@packages/core-shared';
import richTextHtml from '../components/richTextHtml';
import { useFirebase } from '@packages/web-shared';
import { ReferralDocumentsUsedList } from '../components/ReferralDocumentsUsedList';
import { GenerateByAiButton } from '../components/GenerateByAiButton';
import styles from '../components/GenerateByAiButton/GenerateByAiButton.module.css';
import { FormFieldset } from '@percihealth/react';

interface Props {
  caseId: string;
  referralId: string | null;
  currentForm: InterimCareSummaryData;
  onChange: (data: InterimCareSummaryAiGenerated) => void;
}

export const InterimCareSummaryGenerateByAiButton = ({
  caseId,
  referralId,
  currentForm,
  onChange,
}: Props) => {
  const { auth } = useFirebase();

  const [aiFormResponse, setAiFormResponse] =
    useState<InterimCareSummaryAiGeneratedResponse | null>(null);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const generateAiForm = useCallback(
    async (data: AiCareDocumentGenerate) => {
      const result = await docsApiRepository.generateAiInterimCareSummaryForm(
        caseId,
        data,
      );
      setAiFormResponse(result);
    },
    [docsApiRepository],
  );

  return (
    <GenerateByAiButton
      caseId={caseId}
      referralId={referralId}
      generateAiForm={generateAiForm}
      onFillForm={() => {
        onChange(aiFormResponse!.form!);
      }}
    >
      <h2 className={styles.header}>AI generated form</h2>

      <FormFieldset title="Documents" className={styles.fieldset}>
        <ReferralDocumentsUsedList
          appointments={aiFormResponse?.appointmentsWithTranscriptions ?? []}
          documents={aiFormResponse?.documentsUsed ?? []}
        />
      </FormFieldset>

      <FormFieldset title="Form changes" className={styles.fieldset}>
        <div className="full-width">
          <table className={styles.table}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Current form</th>
                <th>Incoming AI data</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Patient</td>
                <td>Cancer type:</td>
                <td>{currentForm.patient.cancerType}</td>
                <td>{aiFormResponse?.form.patient?.cancerType}</td>
              </tr>

              <tr>
                <td></td>
                <td>DOB:</td>
                <td>
                  {dateToLondonNumericDateString(currentForm.patient.dob)}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.patient?.dob,
                    ) !== dateToLondonNumericDateString(currentForm.patient.dob)
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form.patient?.dob,
                  )}
                </td>
              </tr>

              <tr>
                <td>Work</td>
                <td>Date signed-off work:</td>
                <td>
                  {dateToLondonNumericDateString(
                    currentForm.patient.work.dateSignedOff,
                  )}
                </td>
                <td
                  className={
                    dateToLondonNumericDateString(
                      aiFormResponse?.form?.patient?.work?.dateSignedOff,
                    ) !==
                    dateToLondonNumericDateString(
                      currentForm.patient.work.dateSignedOff,
                    )
                      ? styles.changed
                      : undefined
                  }
                >
                  {dateToLondonNumericDateString(
                    aiFormResponse?.form.patient?.work?.dateSignedOff,
                  )}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Job title:</td>
                <td>{currentForm.patient.work.jobTitle}</td>
                <td
                  className={
                    aiFormResponse?.form?.patient?.work?.jobTitle !==
                    currentForm.patient.work.jobTitle
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work?.jobTitle}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Work pattern:</td>
                <td>{currentForm.patient.work.workPattern}</td>
                <td
                  className={
                    (aiFormResponse?.form?.patient?.work?.workPattern ?? '') !==
                    (currentForm.patient.work.workPattern ?? '')
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work?.workPattern}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Hide hoping to return to work:</td>
                <td>
                  {currentForm.patient.work.hideHopingToReturnToWorkIn
                    ? 'Yes'
                    : 'No'}
                </td>
                <td
                  className={
                    (aiFormResponse?.form?.patient?.work
                      ?.hideHopingToReturnToWorkIn ?? false) !==
                    (currentForm.patient.work.hideHopingToReturnToWorkIn ??
                      false)
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work
                    ?.hideHopingToReturnToWorkIn
                    ? 'Yes'
                    : 'No'}
                </td>
              </tr>

              <tr>
                <td></td>
                <td>Hoping to return to work in:</td>
                <td>{currentForm.patient.work.hopingToReturnToWorkIn}</td>
                <td
                  className={
                    aiFormResponse?.form?.patient?.work
                      ?.hopingToReturnToWorkIn !==
                    currentForm.patient.work.hopingToReturnToWorkIn
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form.patient?.work?.hopingToReturnToWorkIn}
                </td>
              </tr>

              <tr>
                <td>Other</td>
                <td>Expert summary:</td>
                <td>{richTextHtml(currentForm.expertSummary?.motivation)}</td>
                <td
                  className={
                    aiFormResponse?.form?.expertSummary?.motivation !==
                    currentForm.expertSummary?.motivation
                      ? styles.changed
                      : undefined
                  }
                >
                  {aiFormResponse?.form?.expertSummary?.motivation
                    ? richTextHtml(
                        aiFormResponse?.form?.expertSummary?.motivation,
                      )
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </FormFieldset>
    </GenerateByAiButton>
  );
};
