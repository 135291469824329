import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import styles from './CreateWorkFocusedPlanPage.module.scss';
import {
  EbpCase,
  IamCancerOptionsWithEmptyValue,
  cancerStatusToString,
  CareDocumentType,
  WorkFocusedPlanData,
  WorkFocusedPlanDataSchema,
  ReferralLandg,
  displayEbpDocumentType,
  MycawInitialTypeform,
  MycawInitialTypeformHelper,
  Referral,
  ReferralType,
  getFullname,
  earliestDayTime,
  deepPartialOrNullable,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikDatepicker,
  FormikDropdownSearch,
  FormikInputText,
  Spinner,
} from '@percihealth/react';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { CareDocumentsApiRepository } from '@packages/web-shared/api/care-documents';
import { getDownloadURL, ref } from 'firebase/storage';
import DocumentColumns from '../components/DocumentColumns';
import OvercomeChallenges from '../components/OvercomeChallenges';
import { PdfGeneratedModal } from '../components/PdfGeneratedModal';
import { useFirebase } from '@packages/web-shared';
import { useLoadPcp } from '../pcp/useLoadPcp';
import {
  useLoadWorkFocusedPlan,
  useLoadWorkFocusedPlanAutosaved,
} from './useLoadWorkFocusedPlan';
import { defaultWorkImpacts } from './defaultWorkImpacts';
import { FormErrorsTooltip } from '../../../FormErrorsTooltip';
import { disableSubmitButton } from '../../../../helpers/formik';

export default function CreateWorkFocusedPlanPage() {
  const params = useParams();
  const caseId = params?.caseId ?? null;

  if (!caseId) {
    return <div>Case ID (email) should be specified</div>;
  }

  const { storage, auth } = useFirebase();

  const [openPdfModal, setOpenPdfModal] = useState(false);
  useEffect(() => {
    setOpenPdfModal(false);
  }, [caseId]);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useGetFirestoreDocument<EbpCase>('cases', caseId);

  const { record: mycawInitialForm } =
    useGetFirestoreDocument<MycawInitialTypeform>(
      'forms',
      ebpCase?.forms?.mycawInitialFormId,
    );

  const initialTypeformHelper = useMemo(
    () => new MycawInitialTypeformHelper(mycawInitialForm),
    [mycawInitialForm],
  );

  const docLoader = useLoadWorkFocusedPlan(
    ebpCase?.documents?.workFocusedPlan?.id,
  );
  const docAutosavedLoader = useLoadWorkFocusedPlanAutosaved(caseId);

  const pcpLoader = useLoadPcp(ebpCase?.documents?.pcp?.id);

  const [autosaveError, setAutosaveError] = useState<string | null>(null);
  useEffect(() => {
    setAutosaveError(null);
  }, [caseId]);

  const autoSave = async (values: any) => {
    console.log('autosave');
    try {
      setAutosaveError(null);
      values.caseId = caseId;
      await docsApiRepository.autosaveWorkFocusedPlanForm(values);
    } catch (error: unknown) {
      console.error(error);
      setAutosaveError('Failed to autosave');
    }
  };
  const debouncedAutosave = useRef(debounce(autoSave, 2000));

  const [createResponseError, setCreateResponseError] = useState<string | null>(
    null,
  );

  const handleOnSubmit = async (
    values: WorkFocusedPlanData,
    actions: FormikHelpers<WorkFocusedPlanData>,
  ) => {
    try {
      actions.setSubmitting(true);
      setCreateResponseError(null);
      const { storageRef } =
        await docsApiRepository.createWorkFocusedPlan(values);

      const previewUrl = await getDownloadURL(ref(storage, storageRef));

      setOpenPdfModal(true);

      // Open the file in a new tab, so it is not automatically downloaded to the local storage
      window.open(previewUrl, '_blank');
    } catch (error: unknown) {
      let errorMsg = '';

      if (error instanceof Error) {
        errorMsg = error.message;
      } else {
        errorMsg = JSON.stringify(error);
      }

      console.error(errorMsg);
      setCreateResponseError(errorMsg);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { record: referral } = useGetFirestoreDocument<Referral>(
    'referrals',
    ebpCase?.referralId,
  );
  const gipCode = useMemo(() => {
    if (referral?.type !== ReferralType.landg) {
      return '';
    }

    return (referral as ReferralLandg)?.gip?.code ?? '';
  }, [referral]);

  const formikDependencies = [
    docLoader.record,
    docAutosavedLoader.record,
    pcpLoader.record,
    initialTypeformHelper,
    ebpCase,
    gipCode,
  ];

  const initialValues: WorkFocusedPlanData = useMemo(() => {
    let docData = docAutosavedLoader.record ?? docLoader.record;
    if (docData) {
      // Deep clone
      try {
        // schema cast is required to have Date type instead of strings
        docData = deepPartialOrNullable(WorkFocusedPlanDataSchema).cast(
          JSON.parse(JSON.stringify(docData)),
        ) as WorkFocusedPlanData;
      } catch {
        // intentionally left blank
      }
    }

    const result = docData ?? {
      caseId,
      type: CareDocumentType.WorkFocusedPlan,
      patient: {
        fullName: ebpCase?.patient ? getFullname(ebpCase.patient) : '',
        dob:
          ebpCase?.patient?.dateOfBirth ??
          pcpLoader.record?.patient?.dob ??
          earliestDayTime(new Date()),
        iam: ebpCase?.patient?.cancerStatus
          ? cancerStatusToString(ebpCase?.patient?.cancerStatus)
          : IamCancerOptionsWithEmptyValue[0],
        work: {
          dateSignedOff: earliestDayTime(new Date()),
          hideHopingToReturnToWorkIn: false,
          hopingToReturnToWorkIn: '',
          jobTitle: initialTypeformHelper.getWorkRole() ?? '',
          workPattern: initialTypeformHelper.getWorkPattern() ?? '',
        },
      },
      gip: {
        code: gipCode,
      },
      workImpact: defaultWorkImpacts,
      planToOvercomeChallenges: [],
      dateOfConsultation: earliestDayTime(new Date()),
    };

    if (!result.gip?.code) {
      result.gip = {
        code: gipCode,
      };
    }

    return result;
  }, [...formikDependencies]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: WorkFocusedPlanDataSchema.isValidSync(initialValues),
    // Show previous PCP info if it was specified previously
    initialValues: initialValues,
    validationSchema: WorkFocusedPlanDataSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [...formikDependencies]);

  useEffect(() => {
    if (formik?.dirty) {
      debouncedAutosave.current(formik.values);
    }
  }, [formik?.values, formik?.dirty]);

  if (caseLoading || docLoader.loading) {
    return <Spinner />;
  }

  if (caseLoadingErrorMessage || !ebpCase) {
    return (
      <AlertError>{caseLoadingErrorMessage ?? 'Not found case'}</AlertError>
    );
  }

  if (docLoader.errorMessage) {
    return <AlertError>{docLoader.errorMessage}</AlertError>;
  }
  if (docAutosavedLoader.errorMessage) {
    return <AlertError>{docLoader.errorMessage}</AlertError>;
  }

  if (pcpLoader.errorMessage) {
    return <AlertError>{pcpLoader.errorMessage}</AlertError>;
  }

  return (
    <FormikProvider value={formik}>
      <div>
        {autosaveError && <AlertError>{autosaveError}</AlertError>}
        <Form onSubmit={formik.handleSubmit}>
          <FormFieldset title="Patient's details">
            <FormikInputText
              id="patient.fullName"
              name="patient.fullName"
              required
              label="Patient full name"
              value={formik.values.patient.fullName}
            />
            <FormikDatepicker
              id="patient.dob"
              name="patient.dob"
              required
              label="Patient DOB"
              className="w-full"
              value={formik.values.patient.dob}
            />
            <FormikDropdownSearch
              name="patient.iam"
              label="Patient is:"
              required
              multiple={false}
              value={formik.values.patient.iam ?? ''}
              options={IamCancerOptionsWithEmptyValue.map((o) => ({
                value: o,
                name: o,
              }))}
            />
          </FormFieldset>
          <FormFieldset title="Patient work details">
            <FormikDatepicker
              id="patient.work.dateSignedOff"
              name="patient.work.dateSignedOff"
              required
              label="Date signed-off work"
              value={formik.values.patient.work.dateSignedOff}
            />
            <FormikInputText
              id="patient.work.jobTitle"
              name="patient.work.jobTitle"
              required
              label="Job title"
              value={formik.values.patient.work.jobTitle}
            />
            <FormikInputText
              id="patient.work.workPattern"
              name="patient.work.workPattern"
              required
              label="Work pattern"
              value={formik.values.patient.work.workPattern}
            />
            <FormikInputText
              id="patient.work.hopingToReturnToWorkIn"
              name="patient.work.hopingToReturnToWorkIn"
              required
              label="Hoping to return to work in"
              value={formik.values.patient.work.hopingToReturnToWorkIn}
              checked={!formik.values.patient.work.hideHopingToReturnToWorkIn}
              disabled={formik.values.patient.work.hideHopingToReturnToWorkIn}
              onCheckedChange={() => {
                const newHideHopingToReturnToWorkIn =
                  !formik.values.patient.work.hideHopingToReturnToWorkIn;
                if (newHideHopingToReturnToWorkIn) {
                  formik.setFieldValue(
                    'patient.work.hopingToReturnToWorkIn',
                    '',
                    true,
                  );
                }
                formik.setFieldValue(
                  'patient.work.hideHopingToReturnToWorkIn',
                  newHideHopingToReturnToWorkIn,
                  true,
                );
              }}
            />
            <FormikInputText
              id="gip.code"
              name="gip.code"
              required
              disabled={!!gipCode}
              label="Group income protection code"
              prefix="GIP-"
              value={formik.values.gip.code}
            />
          </FormFieldset>
          <FormFieldset title="Manual">
            <FormikDatepicker
              id="dateOfConsultation"
              name="dateOfConsultation"
              required
              label="Date of consultation"
              value={formik.values.dateOfConsultation}
            />
          </FormFieldset>
          <FormFieldset title="How cancer is impacting your work">
            <DocumentColumns
              columns={formik.values.workImpact}
              onChange={(arr) => {
                formik.setFieldValue('workImpact', arr);
              }}
            />
          </FormFieldset>

          <FormFieldset title="Plan to help overcome challenges to getting back to work">
            <OvercomeChallenges
              values={formik.values.planToOvercomeChallenges}
              onChange={(arr) => {
                formik.setFieldValue('planToOvercomeChallenges', arr);
              }}
            />
          </FormFieldset>
          <hr />
          <div className={styles.full}>
            <FormErrorsTooltip formik={formik}>
              <Button
                type="submit"
                className={styles.submit}
                fullWidth
                disabled={disableSubmitButton(formik)}
                submitting={formik.isSubmitting}
              >
                Create{' '}
                {displayEbpDocumentType(CareDocumentType.WorkFocusedPlan)}
              </Button>
            </FormErrorsTooltip>
          </div>
          {createResponseError && (
            <AlertError>{createResponseError}</AlertError>
          )}
        </Form>
      </div>
      <PdfGeneratedModal
        caseId={caseId}
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
      />
    </FormikProvider>
  );
}
