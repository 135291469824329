import { object, string, date, InferType } from 'yup';
import { AiCareDocumentTreatmentDetailsSchema } from './AiCareDocumentTreatmentDetailsSchema';
import { baseAiGeneratedResponseSchema } from './baseAiGeneratedResponseSchema';
import { deepPartialOrNullable } from '../../../../helpers';
import {
  EndOfCareSummaryExpertSummarySchema,
  EndOfCareSummaryReturnToWorkSchema,
} from '../endOfCareSummary';

export const EndOfCareSummaryAiGeneratedSchema = object().shape({
  patient: object()
    .shape({
      dob: date().nullable().notRequired(),
      cancerType: string().nullable().notRequired(),
      dateOfDiagnosis: date().nullable().notRequired(),
    })
    .nullable()
    .notRequired(),

  dateOfConsultation: date().nullable().notRequired(),

  endOfCareDate: date().nullable().notRequired(),

  treatmentDetails: deepPartialOrNullable(AiCareDocumentTreatmentDetailsSchema)
    .nullable()
    .notRequired(),

  returnToWorkStatus: deepPartialOrNullable(
    EndOfCareSummaryReturnToWorkSchema,
  ).notRequired(),

  additionalCommunicationNotes: string().nullable().notRequired(),

  expertSummary: deepPartialOrNullable(EndOfCareSummaryExpertSummarySchema)
    .nullable()
    .notRequired(),
});

export type EndOfCareSummaryAiGenerated = InferType<
  typeof EndOfCareSummaryAiGeneratedSchema
>;

export const EndOfCareSummaryAiGeneratedResponseSchema =
  baseAiGeneratedResponseSchema(EndOfCareSummaryAiGeneratedSchema);

export type EndOfCareSummaryAiGeneratedResponse = InferType<
  typeof EndOfCareSummaryAiGeneratedResponseSchema
>;
