import { Appointment, dateToLondonLongDateString } from '@packages/core-shared';

import styles from './SelectAppointmentsTranscriptionsList.module.css';
import { dateTimeToLondonDateTimeString } from '@packages/core-shared';
import { useLoadServices } from '@packages/web-shared/api';
import { Checkbox } from '@percihealth/react';

export const SelectAppointmentsTranscriptionsList = ({
  appointments,
  selectedAppointments,
  onChange,
}: {
  appointments: Appointment[];
  selectedAppointments: string[];
  onChange: (selectedAppointments: string[]) => void;
}) => {
  const servicesLoader = useLoadServices();

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Apt. ID</th>
            <th>Service</th>
            <th>Apt. date</th>
            <th>Transcription time</th>
          </tr>
        </thead>

        <tbody>
          {appointments.map((apt) => (
            <tr>
              <td>
                <Checkbox
                  className={styles.checkbox}
                  value={''}
                  text={apt.id}
                  checked={selectedAppointments.includes(apt.id)}
                  onChange={(e) => {
                    let newSelectedAppointments = [...selectedAppointments];
                    if (
                      e.target.checked &&
                      !newSelectedAppointments.includes(apt.id)
                    ) {
                      newSelectedAppointments.push(apt.id);
                    } else {
                      newSelectedAppointments = newSelectedAppointments.filter(
                        (id) => id !== apt.id,
                      );
                    }
                    onChange(newSelectedAppointments);
                  }}
                />
              </td>
              <td>
                {apt.services
                  ?.filter(Boolean)
                  .map((srv) => (
                    <div key={srv}>
                      {servicesLoader.services[srv!.toString()]?.name}
                    </div>
                  ))}
              </td>
              <td>{dateToLondonLongDateString(apt.date)}</td>
              <td>
                {apt.transcriptions?.map((tr) => (
                  <div key={tr.path}>
                    {' '}
                    {dateTimeToLondonDateTimeString(tr.createdAt)}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className={styles.note}>
        The missed transcription may negatively result on the AI form
        generation.
        <br />
        If the appointment transcription is missed, please try again in several
        minutes. It may take up to 10 minutes to transcribe an appointment.
      </p>
    </div>
  );
};
