import { Checkbox } from '@percihealth/react';
import styles from './SelectReferralDocumentsList.module.css';

export const SelectReferralDocumentsList = ({
  documents,
  selectedDocumentsPaths: selectedDocumentsPaths,
  onChange,
}: {
  documents: { name: string; path: string }[];
  selectedDocumentsPaths: string[];
  onChange: (selectedDocuments: string[]) => void;
}) => {
  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Document Name</th>
          </tr>
        </thead>

        <tbody>
          {documents.map((document) => (
            <tr>
              <td>
                <Checkbox
                  className={styles.checkbox}
                  value={''}
                  text={document.name}
                  checked={selectedDocumentsPaths.includes(document.path)}
                  onChange={(e) => {
                    let newSelectedDocuments = [...selectedDocumentsPaths];
                    if (
                      e.target.checked &&
                      !newSelectedDocuments.includes(document.path)
                    ) {
                      newSelectedDocuments.push(document.path);
                    } else {
                      newSelectedDocuments = newSelectedDocuments.filter(
                        (id) => id !== document.path,
                      );
                    }
                    onChange(newSelectedDocuments);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
